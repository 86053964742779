<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card>
        <div class="vx-row mb-6" style="width: 100%">
          <vs-button
            class="ml-4 mt-2"
            color="danger"
            icon-pack="feather"
            icon="icon-arrow-left"
            @click="handleBack()"
            >Back</vs-button
          >
        </div>
        <span>
          <h4 class="mb-4" v-if="this.$route.params.id">Edit User</h4>
          <h4 class="mb-4" v-else>Add User</h4>
        </span>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Work Group</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="Work Group"
              v-validate="'required'"
              placeholder="Select work group"
              v-model="responseData.work_group_name"
              @input="setSelected"
              label="name"
              :options="options"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('Work Group')"
              >{{ errors.first("Work Group") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Role</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full" :data="data">
            <ul :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <li>
                <vs-checkbox
                  name="role"
                  :checked="data[indextr].selected"
                  v-on:click="addChecked(data[indextr].id)"
                  >{{ data[indextr].display_name }}</vs-checkbox
                >
              </li>
            </ul>
            <span class="text-danger text-sm" v-show="errors.has('role')">{{
              errors.first("role")
            }}</span>
          </div>
          <div class="vx-col sm:w-3/5 w-full">
            <div class="mb-6">
              <label class="vs-input--label">Sales Area</label>
              <v-select
                placeholder="Select territory area"
                v-model="responseData.territory_area"
                @input="setSelectedTerritoryArea"
                label="name"
                :options="territoryArea.options"
                :multiple="true"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.code }} {{ option.name }}</span>
                </template>
              </v-select>
            </div>
            <div class="mb-6">
              <label class="vs-input--label">Territory</label>
              <v-select
                placeholder="Select territory"
                v-model="responseData.territory"
                @input="setSelectedTerritory"
                label="name"
                :options="territory.options"
                :multiple="true"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.code }} {{ option.name }}</span>
                </template>
              </v-select>
            </div>
            <div class="mb-6" v-if="untukSales">
              <label class="vs-input--label">Sales Channel</label>
              <v-select
                placeholder="Select sales channel"
                name="Sales Channel"
                v-validate="'required'"
                v-model="responseData.sales_channel"
                @input="setSelectedSalesChannel"
                label="name"
                :options="sales_channel.options"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.code }} {{ option.name }}</span>
                </template>
              </v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('Sales Channel')"
                >{{ errors.first("Sales Channel") }}</span
              >
            </div>
            <div class="mb-6" v-else>
              <label class="vs-input--label">Sales Channel</label>
              <v-select
                placeholder="Select sales channel"
                v-model="responseData.sales_channel"
                @input="setSelectedSalesChannel"
                label="name"
                :options="sales_channel.options"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.code }} {{ option.name }}</span>
                </template>
              </v-select>
            </div>
            <div class="mb-6" v-if="untukSales">
              <label class="vs-input--label">Zone</label>
              <v-select
                placeholder="Select zone"
                v-model="responseData.zone"
                @input="setSelectedZone"
                label="name"
                :options="zone.options"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.code }} {{ option.name }}</span>
                </template>
              </v-select>
            </div>
            <!-- <div class="mb-6" v-if="untukSales">
              <label class="vs-input--label">Salesman SubType</label>
              <v-select
                placeholder="Select salesman subtype"
                v-model="responseData.workPosition"
                @input="setSelectedWorkPosition"
                label="Name"
                :options="workPosition.options"
              >
                <template slot="option" slot-scope="option">
                  <span>{{ option.Name }}</span>
                </template>
              </v-select>
            </div> -->
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-if="this.$route.params.id">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Status Active</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <vs-switch
              color="primary"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="statusActive"
            >
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4" v-if="this.$route.params.id">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Status SFA</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <vs-switch
              color="primary"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="statusSfa"
            >
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>External Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="External Code"
              class="w-full"
              v-model="responseData.code_external"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Work Id Number</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="Work ID Number"
              v-validate="'required'"
              class="w-full"
              v-model="responseData.work_id_number"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('Work ID Number')"
              >{{ errors.first("Work ID Number") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Id Number</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="ID Number"
              v-validate="'numeric|required'"
              class="w-full"
              v-model="responseData.id_number"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ID Number')"
              >{{ errors.first("ID Number") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Name</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="name"
              v-validate="'required'"
              class="w-full"
              v-model="responseData.name"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Registration Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <datepicker
              class="w-full"
              v-validate="'required'"
              :inline="false"
              name="RegistrationDate"
              v-model="responseData.registration_date"
              placeholder="Select Registration Date"
            ></datepicker>
            <span
              class="text-danger text-sm"
              v-show="errors.has('RegistrationDate')"
              >{{ errors.first("RegistrationDate") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Country</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="Country"
              v-validate="'required'"
              placeholder="Select Country"
              v-model="responseData.country_name"
              @input="setSelectedCountry"
              label="name"
              :options="country.options"
            />
            <span class="text-danger text-sm" v-show="errors.has('Country')">{{
              errors.first("Country")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Province</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="Province"
              v-validate="'required'"
              placeholder="Select Province"
              v-model="responseData.province_name"
              @input="setSelectedProvince"
              label="name"
              :options="province.options"
            />
            <span class="text-danger text-sm" v-show="errors.has('Province')">{{
              errors.first("Province")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>City</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="City"
              v-validate="'required'"
              placeholder="Select City"
              v-model="responseData.city_name"
              @input="setSelectedCity"
              label="name"
              :options="city.options"
            />
            <span class="text-danger text-sm" v-show="errors.has('City')">{{
              errors.first("City")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>District</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="District"
              placeholder="Select District"
              v-model="responseData.district_name"
              @input="setSelectedDistrict"
              label="name"
              :options="district.options"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Sub District</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <v-select
              name="District"
              placeholder="Select Sub District"
              v-model="responseData.sub_district_name"
              @input="setSelectedSubDistrict"
              label="name"
              :options="sub_district.options"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Postal Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="postal code"
              class="w-full"
              v-model="responseData.postal_code"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Address</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              v-validate="'required'"
              name="address"
              class="w-full"
              v-model="responseData.address"
            />
            <span class="text-danger text-sm" v-show="errors.has('address')">{{
              errors.first("address")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Mobile</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="mobile"
              v-validate="'numeric|required'"
              class="w-full"
              v-model="responseData.mobile"
            />
            <span class="text-danger text-sm" v-show="errors.has('mobile')">{{
              errors.first("mobile")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Email</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              name="email"
              v-validate="'email|required'"
              class="w-full"
              v-model="responseData.email"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Password</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              v-validate="'required'"
              name="password"
              type="password"
              class="w-full"
              v-model="responseData.password"
            />
            <span class="text-danger text-sm" v-show="errors.has('password')">{{
              errors.first("password")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Avatar</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <input
              name="file"
              class="w-full inputx"
              v-if="uploadReady"
              type="file"
              ref="file"
              accept=".jpg, .jpeg, .png"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span></span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <img
              style="max-height: 170px; max-width: auto"
              :src="responseData.avatar"
            />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4 mt-3">
          <div class="vx-col sm:w-4/5 w-full ml-auto">
            <vs-button
              v-if="this.$route.params.id"
              v-on:click="handleSubmit"
              class="mr-3 mb-2"
              >Update</vs-button
            >
            <vs-button v-else v-on:click="handleSubmit" class="mr-3 mb-2"
              >Create</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
  
  <script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    vSelect,
    Datepicker,
  },
  created() {
    this.getDataCountry();
    if (this.$route.params.id) {
      this.getData();
    }
    this.getRole(1);
    this.getWorkGroup();
    this.getAllDataTerritoryArea();
    this.getSalesChannel();
    // this.getDataWorkPosition();
  },
  data() {
    return this.initialState();
  },
  methods: {
    handleBack() {
      this.$router.push("/setting/user");
    },
    addChecked(val) {
      // for sales
      if (val == 8) {
        this.untukSales = true;
      } else {
        this.untukSales = false;
      }

      if (this.selected.includes(val)) {
        this.selected = [];
        this.responseData.role = [];
      } else {
        this.selected = [val];
        this.responseData.role = [val];
      }
      this.getRole(1);
    },
    initialState() {
      return {
        statusAvatar: "old",
        statusUnlock: false,
        statusActive: false,
        statusSfa: false,
        isThisUserGrantedUnlock: false,
        isPricingLock: false,
        untukSales: false,
        uploadReady: true,
        data: [],
        roleGroup: null,
        selected: [],
        temp_territory_area_ids: [],
        territoryArea: {
          selected: 0,
          options: [],
        },
        territory: {
          selected: 0,
          options: [],
        },
        zone: {
          selected: 0,
          options: [],
        },
        // workPosition: {
        //   selected: 0,
        //   options: [],
        // },
        sales_channel: {
          selected: [],
          options: [],
        },
        country: {
          selected: 0,
          options: [],
        },
        province: {
          selected: 0,
          options: [],
        },
        city: {
          selected: 0,
          options: [],
        },
        district: {
          selected: 0,
          options: [],
        },
        sub_district: {
          selected: 0,
          options: [],
        },
        options: [],
        responseData: {
          work_group_name: "",
          work_group_id: "",
          role: [],
          code_external: "",
          work_id_number: "",
          id_number: "",
          name: "",
          address: "",
          mobile: "",
          email: "",
          password: "",
          avatar: "default.png",
          territory_area: "",
          territory: "",
          zone: "",
          // workPosition: "",
          sales_channel: "",
          country_name: "",
          province_name: "",
          city_name: "",
          district_name: "",
          sub_district_name: "",
          postal_code: "",
          registration_date: new Date().toISOString().slice(0, 10),
        },
      };
    },
    getDataTerritory(territoryAreaID) {
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/territory-master", {
          params: {
            order: "code",
            territory_area_ids: territoryAreaID.toString(),
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSalesChannel() {
      this.$vs.loading();
      this.$http.get("api/oms/v1/setting-user/sales-channel").then((resp) => {
        if (resp.status == "success") {
          this.sales_channel.options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getDataZone(territoryIDs) {
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/zone/territory", {
          params: {
            "territory_ids": territoryIDs,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.zone.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    // getDataWorkPosition() {
    //   this.$vs.loading();
    //   this.$http
    //     .get("api/sfa/v1/master/work-positions")
    //     .then((resp) => {
    //       if (resp.status == "OK") {
    //         //console.log(resp);
    //         this.workPosition.options = resp.data;
    //         this.$vs.loading.close();
    //       } else {
    //         this.$vs.loading.close();
    //         //console.log(resp.data);
    //       }
    //     });
    // },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/territory-area-master", {
          params: {
            order: "code",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territoryArea.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataCountry() {
      let param = {
        length: "all",
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/country", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.country.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataProvince() {
      let param = {
        length: "all",
        country_id: this.country.selected,
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/province", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.province.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataCity() {
      let param = {
        length: "all",
        province_id: this.province.selected,
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/city", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.city.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataDistrict() {
      let param = {
        length: "all",
        city_id: this.city.selected,
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/district", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.district.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataSubDistrict() {
      let param = {
        length: "all",
        district_id: this.district.selected,
        city_id: this.city.selected,
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/subdistrict", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.sub_district.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setSelectedCountry(value) {
      if (value == null) {
        this.country.selected = 0;
        this.responseData.country_name = "";
      } else {
        this.country.selected = value.id;
        this.responseData.country_name = value.name;
      }
      this.province.options = [];
      this.responseData.province_name = "";
      this.city.options = [];
      this.responseData.city_name = "";
      this.district.options = [];
      this.responseData.district_name = "";
      this.sub_district.options = [];
      this.responseData.sub_district_name = "";
      this.responseData.postal_code = "";
      this.getDataProvince(value.id);
    },
    setSelectedProvince(value) {
      if (value == null) {
        this.province.selected = 0;
        this.responseData.province_name = "";
      } else {
        this.province.selected = value.id;
        this.responseData.province_name = value.name;
      }
      this.city.options = [];
      this.responseData.city_name = "";
      this.district.options = [];
      this.responseData.district_name = "";
      this.sub_district.options = [];
      this.responseData.sub_district_name = "";
      this.responseData.postal_code = "";
      this.getDataCity(value.id);
    },
    setSelectedCity(value) {
      if (value == null) {
        this.city.selected = 0;
        this.responseData.city_name = "";
      } else {
        this.city.selected = value.id;
        this.responseData.city_name = value.name;
      }
      this.district.options = [];
      this.responseData.district_name = "";
      this.sub_district.options = [];
      this.responseData.sub_district_name = "";
      this.responseData.postal_code = "";
      this.getDataDistrict(value.id);
    },
    setSelectedDistrict(value) {
      if (value == null) {
        this.district.selected = 0;
        this.responseData.district_name = "";
      } else {
        this.district.selected = value.id;
        this.responseData.district_name = value.name;
      }
      this.sub_district.options = [];
      this.responseData.sub_district_name = "";
      this.responseData.postal_code = "";
      this.getDataSubDistrict(value.id);
    },
    setSelectedSubDistrict(value) {
      if (value == null) {
        this.sub_district.selected = 0;
        this.responseData.sub_district_name = "";
        this.responseData.postal_code = "";
      } else {
        this.sub_district.selected = value.id;
        this.responseData.sub_district_name = value.name;
        this.responseData.postal_code = value.postal_code;
      }
    },
    setSelectedTerritoryArea(value) {
      if (value.length > 0) {
        this.territoryArea.selected = value;
        this.responseData.territory_area = value;
      } else {
        this.territoryArea.selected = [];
        this.responseData.territory_area = [];
      }
      var territory_area_ids = [];
      value.forEach(function (element) {
        territory_area_ids.push(element.id);
      });
      this.temp_territory_area_ids = territory_area_ids
      this.getDataTerritory(territory_area_ids);
    },
    setSelectedTerritory(value) {
      if (value.length > 0) {
        this.territory.selected = value;
        this.responseData.territory = value;
      } else {
        this.territory.selected = [];
        this.responseData.territory = [];
      }
      var territory_ids = [];
      value.forEach(function (element) {
        territory_ids.push(element.id);
      });
      this.getDataZone(territory_ids);
    },
    setSelectedZone(value) {
      if (value == null) {
        this.zone.selected = 0;
        this.responseData.zone = "";
      } else {
        this.zone.selected = value.id ? value.id : value.ID;
        this.responseData.zone = value.name;
      }
    },
    // setSelectedWorkPosition(value) {
    //   console.log(value)
    //   if (value == null) {
    //     this.workPosition.selected = 0;
    //     this.responseData.workPosition = "";
    //   } else {
    //     this.workPosition.selected = value.id ? value.id : value.ID;
    //     this.responseData.workPosition = value.Name;
    //   }
    // },
    setSelectedSalesChannel(value) {
      if (value == null) {
        this.sales_channel.selected = [];
        this.responseData.sales_channel = "";
      } else {
        this.sales_channel.selected = [value.id];
        this.responseData.sales_channel = value.name;
      }

      console.log(this.sales_channel.selected);
    },
    // work group
    setSelected(value) {
      if (value == null) {
        this.responseData.work_group_name = "";
        this.responseData.work_group_id = "";
      } else {
        this.responseData.work_group_name = value.name;
        this.responseData.work_group_id = value.id;
        this.roleGroup = value.role_group;
        this.getRole(1);
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("api/sfa/v1/setting/user-personal/" + this.$route.params.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.responseData.code_external = resp.data.code_external;
            this.responseData.work_group_name = resp.data.work_group_name;
            this.responseData.role = resp.data.role;
            this.roleGroup = resp.data.role_group;
            this.responseData.work_id_number = resp.data.work_id_number;
            this.responseData.work_group_id = resp.data.work_group_id;
            this.responseData.id_number = resp.data.id_number;
            this.responseData.name = resp.data.name;
            this.responseData.address = resp.data.address;
            this.responseData.mobile = resp.data.mobile;
            this.responseData.email = resp.data.email;
            this.responseData.territory_area = resp.data.territory_areas;
            this.responseData.territory = resp.data.territories;
            this.territoryArea.selected = resp.data.territory_area_ids;
            this.temp_territory_area_ids = resp.data.territory_area_ids;
            this.territory.selected = resp.data.territories;
            this.responseData.avatar =
              resp.data.avatar + "?" + new Date().getTime();
            this.responseData.zone = resp.data.zone_name;
            this.zone.selected = resp.data.zone_id;
            // this.workPosition.selected = {ID: resp.data.work_position_id, Name: resp.data.work_position_name};
            // this.responseData.workPosition = {ID: resp.data.work_position_id, Name: resp.data.work_position_name};
            this.responseData.sales_channel = resp.data.sales_channel_name[0];
            this.sales_channel.selected = resp.data.sales_channel_id;
            this.responseData.country_name = resp.data.country;
            this.responseData.province_name = resp.data.province;
            this.responseData.city_name = resp.data.city;
            this.responseData.district_name = resp.data.district;
            this.responseData.sub_district_name = resp.data.sub_district;
            this.responseData.postal_code = resp.data.postal_code;
            this.statusActive = resp.data.status_active;
            this.statusSfa = resp.data.status_sfa;
            this.responseData.registration_date = resp.data.registration_date;

            this.getDataTerritory(resp.data.territory_area_ids);

            var country = resp.data.country;
            var province = resp.data.province;
            var city = resp.data.city;
            var district = resp.data.district;
            this.setDataAddress(country, province, city, district);

            this.$userLogin.avatar =
              resp.data.avatar + "?" + new Date().getTime();
          }
          this.getRole(0);
        });
    },
    setDataAddress(country, province, city, district) {
      // country
      var a;
      this.country.options.forEach(function (i) {
        if (i.name == country) {
          a = i.id;
        }
      });
      this.country.selected = a;
      let param = {
        length: "all",
        country_id: a,
      };
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/setting-user/province", {
          params: param,
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.province.options = resp.data.records;

            // province
            var b;
            resp.data.records.forEach(function (i) {
              if (i.name == province) {
                b = i.id;
              }
            });
            this.province.selected = b;
            let param = {
              length: "all",
              province_id: b,
            };
            this.$http
              .get("api/oms/v1/setting-user/city", {
                params: param,
              })
              .then((resp) => {
                if (resp.status == "success") {
                  this.city.options = resp.data.records;

                  // city
                  var c = 0;
                  resp.data.records.forEach(function (i) {
                    if (i.name == city) {
                      c = i.id;
                    }
                  });
                  let param = {
                    length: "all",
                    city_id: c,
                  };
                  this.$http
                    .get("api/oms/v1/setting-user/district", {
                      params: param,
                    })
                    .then((resp) => {
                      if (resp.status == "success") {
                        this.district.options = resp.data.records;

                        // district
                        var d = 0;
                        resp.data.records.forEach(function (i) {
                          if (i.name == district) {
                            d = i.id;
                          }
                        });

                        if (c != 0 && d != 0) {
                          let param = {
                            length: "all",
                            district_id: d,
                            city_id: c,
                          };
                          this.$http
                            .get("api/oms/v1/setting-user/subdistrict", {
                              params: param,
                            })
                            .then((resp) => {
                              if (resp.status == "success") {
                                this.sub_district.options = resp.data.records;
                              } else {
                                console.log(resp.data);
                              }
                            });
                        }
                      } else {
                        console.log(resp.data);
                      }
                    });
                } else {
                  this.$vs.loading.close();
                  console.log(resp.data);
                }
              });
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getRole(stat) {
      this.$http
        .get("/api/sfa/v1/setting/role", {
          params: {
            source: "SFA",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var y = resp.data.records;
            var x = this.selected;
            var z = [];
            var _this = this;
            if (this.$route.params.id && stat == 0) {
              _this.responseData.role.forEach(function (i) {
                x.push(i.id);
              });
            }
            y.forEach(function (element, index) {
              if (x.includes(element.id)) {
                y[index]["selected"] = true;
                if (y[index].name == "sales") {
                  _this.untukSales = true;
                }
              } else {
                y[index]["selected"] = false;
              }
            });

            if (this.roleGroup != null) {
              y.forEach(function (element, index) {
                if (_this.roleGroup == element.group) {
                  z.push(y[index]);
                }
              });
            } else {
              z = y;
            }
            this.data = z;
          }
        });
    },
    getWorkGroup() {
      this.$vs.loading();
      this.$http.get("api/oms/v1/setting-user/work-group").then((resp) => {
        this.$vs.loading.close();
        if (resp.status == "success") {
          this.options = resp.data;
        }
      });
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.selected.length < 1) {
          this.errors.add({
            field: "role",
            msg: "The Role is required",
          });
          result = false;
        }

        if (result) {
          this.$vs.loading();
          var file = this.$refs.file.files[0];
          if (file != null) {
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (!validImageTypes.includes(file.type)) {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var name =
                this.responseData.work_id_number +
                this.responseData.id_number +
                "." +
                file.name.split(".").pop();
              this.responseData.avatar = name;
              this.formData = new FormData();
              this.formData.append("file", file);
              this.formData.append("name", name);

              this.$vs.loading();
              this.$http
                .post("api/sfa/v1/setting/user-personal/avatar", this.formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((resp) => {
                  this.$vs.loading.close();
                  if (resp.status == "success") {
                    if (this.$route.params.id) {
                      this.statusAvatar = "new";
                      this.putData();
                    } else {
                      this.postData();
                    }
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                    });
                  }
                });
            }
          } else {
            if (this.$route.params.id) {
              this.putData();
            } else {
              this.postData();
            }
          }
        }
      });
    },
    paramData() {
      let temp_territory_ids = [];
      if (this.territory.selected) {
        this.territory.selected.forEach(function (i) {
          temp_territory_ids.push(i.id);
        });
      }

      return {
        workGroupName: this.responseData.work_group_name,
        workGroupID: this.responseData.work_group_id,
        role: this.selected,
        workIDNumber: this.responseData.work_id_number,
        idNumber: this.responseData.id_number,
        name: this.responseData.name,
        address: this.responseData.address,
        mobile: this.responseData.mobile,
        email: this.responseData.email,
        password: this.responseData.password,
        avatar: this.responseData.avatar,
        territoryAreaIDs: this.temp_territory_area_ids,
        // territoryAreaID: this.territoryArea.selected, // geperlu dikirim, karna ambil dari territory di BE
        // territoryID: this.territory.selected, diganti jadi multiple territory
        territoryIDs: temp_territory_ids,
        zoneID: this.zone.selected,
        // workPositionID: this.workPosition.selected,
        salesChannelID: this.sales_channel.selected,
        country: this.responseData.country_name,
        province: this.responseData.province_name,
        city: this.responseData.city_name,
        district: this.responseData.district_name,
        subDistrict: this.responseData.sub_district_name,
        postalCode: this.responseData.postal_code,
        statusUnlockPricing: this.statusUnlock,
        statusActive: this.statusActive,
        statusSfa: this.statusSfa,
        registrationDate: moment(this.responseData.registration_date).format(
          "YYYY-MM-DD"
        ),
        codeExternal: this.responseData.code_external,
        source: "ZEST-SFA"
      };
    },
    postData() {
      this.$http
        .post("/api/sfa/v1/setting/user-personal", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New User Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.getData();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
      // this.paramData()
    },
    putData() {
      this.$http
        .put(
          "/api/sfa/v1/setting/user-personal/" + this.$route.params.id,
          this.paramData()
        )
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

            this.getData();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  watch: {
    selected: function (val) {
      if (val.length > 0) {
        this.errors.clear();
      } else {
        this.errors.add({
          field: "role",
          msg: "The Role is required",
        });
      }
    },
  },
};
</script>